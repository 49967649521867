<template>
    <div class="left-pane w-54 px-5 py-12  o1">
        <nav class="sub-nav text-purple">
            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'eggs'}"
                @click.prevent="switchScreen('eggs')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Birds</span>
            </a>

            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'islands'}" 
                @click.prevent="switchScreen('islands')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Islands</span>
            </a>

            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'packages'}" 
                @click.prevent="switchScreen('packages')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Packages</span>
            </a>

            <a href="#" class="link block border border-transparent my-1 py-4 pl-8 pr-2 rounded-lg"
                :class="{active: uiStates.screen == 'others'}" 
                @click.prevent="switchScreen('others')">

                <img src="https://via.placeholder.com/36x36" alt="" class="inline-block w-9 h-9">
                <span class="t inline-block relative ml-1.5 top-0.5">Others</span>
            </a> 
        </nav>
    </div>
    <div class="main-pane flex-grow pt-5 pb-12 pr-5 o2">
        <div class="shop-items flex bg-black-blueish rounded-xl p-5 justify-center items-center ">
            
            <template v-if="uiStates.screen == 'eggs'">

                <div v-for="(pack, index) in offeringPackages[uiStates.screen]" :key="'op-eggs-' + index"
                    class="card w-full lg:w-88 min-h-100 block bg-purple-dark rounded-xl relative p-5 py-8 text-lg mx-3"
                    :data-packId="pack.packId">

                    <div class="nest-wrap block relative w-36 h-34 mx-auto mt-7 o1">
                        <img src="/imgs/nest/nest.png" width="144" alt="" class="nest absolute bottom-0">
                        <img src="/imgs/nest/egg.png" width="67" alt="" class="egg absolute top-0 left-10">
                    </div>
                    <div class="block text-white text-center mt-4">
                        <p class="status text-4xl">Buy Bird</p>
                        <p class="sub-title mt-4">{{ pack.packDesc }}</p>
                        <div class="cta block mt-4">
                            <a @click.prevent="buyPackage(pack)" href="#" class="btn inline-block min-w-32 h-10 px-4 py-1.5 cursor-pointer">
                                <img src="https://via.placeholder.com/28x28" alt="" class="inline-block relative -top-0.5">
                                <span class="t ml-2.5">{{ pack.price }}</span>
                            </a>
                        </div>
                        
                    </div>
                </div>  

            </template>

            <template v-if="uiStates.screen == 'islands'">

                <div v-for="(pack, index) in offeringPackages[uiStates.screen]" :key="'op-eggs-' + index"
                    class="card w-full lg:w-88 min-h-100 block bg-purple-dark rounded-xl relative p-5 py-8 text-lg mx-3"
                    :data-packId="pack.packId">

                    <div class="image-wrap block relative w-full text-center mt-8">
                        <img src="https://via.placeholder.com/216x140" width="215" alt="" class="inline-block">
                    </div>
                    <div class="block text-white text-center mt-4">
                        <p class="status text-4xl">Buy Island</p>
                        <p class="sub-title mt-4">{{ pack.packDesc }}</p>
                        <div class="cta block mt-4">
                            <a @click.prevent="buyPackage(pack)" href="#" class="btn inline-block min-w-32 h-10 px-4 py-1.5">
                                <img src="https://via.placeholder.com/28x28" alt="" class="inline-block relative -top-0.5">
                                <span class="t ml-2.5">{{ pack.price }}</span>
                            </a>
                        </div>
                        
                    </div>
                </div>

            </template>

            <template v-if="uiStates.screen == 'packages'">

                <div v-for="(pack, index) in offeringPackages[uiStates.screen]" :key="'op-eggs-' + index" 
                    class="card w-full lg:w-88 min-h-100 block bg-purple-dark rounded-xl relative p-5 py-8 text-lg mx-3"
                    :data-packId="pack.packId">

                    <div class="image-wrap block relative w-full text-center mt-8">
                        <img src="https://via.placeholder.com/216x140" width="215" alt="" class="inline-block">
                    </div>
                    <div class="block text-white text-center mt-4">
                        <p class="status text-4xl">Buy Package</p>
                        <p class="sub-title mt-4">{{ pack.packDesc }}</p>
                        <div class="cta block mt-4">
                            <a @click.prevent="buyPackage(pack)" href="#" class="btn inline-block min-w-32 h-10 px-4 py-1.5">
                                <img src="https://via.placeholder.com/28x28" alt="" class="inline-block relative -top-0.5">
                                <span class="t ml-2.5">{{ pack.price }}</span>
                            </a>
                        </div>
                        
                    </div>
                </div>

            </template>

            <template v-if="uiStates.screen == 'others'">

                <div v-for="(pack, index) in offeringPackages[uiStates.screen]" :key="'op-eggs-' + index" 
                    class="card w-full lg:w-88 min-h-100 block bg-purple-dark rounded-xl relative p-5 py-8 text-lg mx-3"
                    :data-packId="pack.packId">

                    <div class="image-wrap block relative w-full text-center mt-8">
                        <img src="https://via.placeholder.com/216x140" width="215" alt="" class="inline-block">
                    </div>
                    <div class="block text-white text-center mt-4">
                        <p class="status text-4xl">Buy Soul Stone</p>
                        <p class="sub-title mt-4">{{ pack.packDesc }}</p>
                        <div class="cta block mt-4">
                            <a @click.prevent="buyPackage(pack)" href="#" class="btn inline-block min-w-32 h-10 px-4 py-1.5">
                                <img src="https://via.placeholder.com/28x28" alt="" class="inline-block relative -top-0.5">
                                <span class="t ml-2.5">{{ pack.price }}</span>
                            </a>
                        </div>
                        
                    </div>
                </div>

            </template>

        </div>
    </div>
    <LightBox ref="resultLB" :show-on-created="false"
        @closed="purchaseResult = null"
        modal-class="w-full lg:w-120 bg-black bg-opacity-90 text-lg rounded-xl px-4 py-5 text-center">

        <template v-if="purchaseResult === true">
            <p class="block text-2xl">Purchase Success!</p>
            <p class="block mt-4">Please head to <a href="/my-account" class="underline">Inventory</a> to check your new item(s)</p>            
        </template>
        <template v-if="purchaseResult === false">
            <p class="block text-2xl">Purchase Fail!</p>
            <p class="block mt-4">Please try again in awhile.</p>        
        </template>  
    </LightBox>
</template>

<script>
import { inject } from "vue";
import ShareMethods from '@/mixins/ShareMethods.js';
import LightBox from '@/components/global/LightBox.vue';
import SMC from '@/helpers/SMC.js'
import API from '@/helpers/Api.js'

const availableScreen = ['eggs', 'islands', 'packages', 'others'];

export default {
    name: 'Offering',
    mixins: [ShareMethods],
    components: {
        LightBox,
    },
    setup() {
        return {
            uiStates: inject('uiStates'),
            eth: inject('eth'),
            birds: inject('birds'),
            lands: inject('lands'),
            tools: inject('tools'),
            user: inject('user'),
            tokens: inject('tokens'),
        };
    },
    data() {
        return {
            web3: null,
            account: null,
            offeringPackages: {
                eggs: [],
                islands: [],
                packages: [],
                others: [],
            },
            purchaseResult: null,
        }
    },
    computed: {
        
    },
    async created() {
        this.uiStates.page = 'offering';
        this.uiStates.screen = null;

        let deep_link = window.location.hash;
        if (deep_link.length > 1) {
            deep_link = deep_link.substr(1);
            console.log('Deep link hash', deep_link);

            const segment = deep_link.split('/');
            console.log('Deep link seg', segment);

            const req_screen = segment[0];
            if (availableScreen.indexOf(req_screen) > -1) {
                this.uiStates.screen = req_screen;   

                if (segment.length > 1) {
                    const req_subScreen = segment[1];
                    if (req_screen == 'inventory') {
                        if (['birds', 'birds-corpse', 'islands', 'eggs'].indexOf(req_subScreen) > -1) {
                            // valid sub screen
                            this.uiStates.subScreen = req_subScreen;
                        }
                    }
                }
            }
        }

        if ( ! this.uiStates.screen) {
            this.uiStates.screen = 'eggs'; // default screen
        }

        // Fetch data
        console.log('Fetch offering packages');
        this.showLoadingBox();
        try {
            const resp = await API.offeringPackages();
            console.log('Offering Packages resp', resp);
            const map = {
                BIRD: 'eggs',
                ISLAND: 'islands',
                MIX: 'packages',
                OTHER: 'others', 
            };
            for (const i in resp) {
                let group = map[resp[i]['type']];
                if (group) {
                    this.offeringPackages[group].push(resp[i]);
                } else {
                    console.error('Invalid package type', resp[i]);
                }
                
            }
            console.log('Parsed data', this.offeringPackages);
            
            this.hideLoadingBox();
        } catch (ex) {
            this.hideLoadingBox();
            console.error('Fetching offering fail', ex);
        }
        
    },
    methods: {
        switchScreen(screen) {
            this.uiStates.screen = screen;
        },
        async buyPackage(pack) {

            let b4Purchase = {
                birds: [],
                lands: [],
            };

            this.birds.forEach((bird) => {
                b4Purchase.birds.push(bird.id);
            });
            this.lands.forEach((land) => {
                b4Purchase.lands.push(land.id);
            });

            try {

                this.showLoadingBox();
                
                const mainTokenContract = new this.eth.w3.eth.Contract(SMC.abis.mainToken, SMC.addresses.mainToken);
                let smc_address = SMC.addresses.buyPackageNFT;
                if (pack.packId == 6) {
                    // Buy soulStone link to farmSMC
                    smc_address = SMC.addresses.farm;
                }

                console.log('SMC address', smc_address);
                    
                const resp = await mainTokenContract.methods.approve(
                    smc_address, 
                    SMC.ethNum(pack.price, this.eth.w3)
                ).send({
                    from: this.user.id
                });
                
                console.log('Metamask approval resp', resp);


                // sample response
                // const resp = {
                //     blockHash: "0xfa78aa05523c0df7679479328e97b65109de8b2260b630740ed89b2855535227",
                //     blockNumber: 12820094,
                //     contractAddress: null,
                //     cumulativeGasUsed: 157894,
                //     events: {},
                //     from: "0xae8e1e332aab95f082b7248ce4eeca08cda5add7",
                //     gasUsed: 21560,
                //     logsBloom: "0x00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
                //     status: true,
                //     to: "0xe0cb9c1ee614f63eff4e388e8af8e925a076d96d",
                //     transactionHash: "0xb44eab926f0c76809198d5d4fda8d076fc966f5253018648ceeb830138e068b0",
                //     transactionIndex: 2,
                //     type: "0x0",
                // }
                
                if (pack.packId != 6) {
                    const be_resp = await API.buyOfferingPackage(
                        pack.packId, resp.blockHash, resp.blockNumber, resp.contractAddress, resp.from, resp.to, resp.transactionHash, resp.type
                    );
                    console.log('BE response', be_resp);

                    let b4_count = {
                        birds: this.birds.length,
                        lands: this.lands.length,
                    };

                    // Hacky
                    if (be_resp.birds) {
                        vApp.$data.birds = be_resp.birds;
                    }
                    if (be_resp.isLands) {
                        vApp.$data.lands = be_resp.isLands;
                    }
                    if (be_resp.tool) {
                        vApp.$data.tools = be_resp.tool;
                    }

                    let after_count = {
                        birds: this.birds.length,
                        lands: this.lands.length, 
                    };

                    console.log('Before purchase count', b4_count);
                    console.log('After purchase count', after_count);
                    // if (after_count.birds > b4_count.birds) {
                    //     alert('New bird found');
                    // }
                    // if (after_count.lands > b4_count.lands) {
                    //     alert('New land found');
                    // }
                    
                } else {
                    // soul stone
                    const be_resp = await API.buyTool('soul_stone', 1, resp.transactionHash);
                    console.log('BE buySoulStone response', be_resp); 
                    this.tools.soul_stone = resp.soul_stone;
                }

                // Update token
                await this.updateTokens();

                this.hideLoadingBox();
                this.purchaseResult = true;
            } catch (error) {
                this.hideLoadingBox();
                console.error('Buy Offering Package fail', error);
                this.purchaseResult = false;
            }

            this.$refs.resultLB.show();
        }
    }
}
</script>

<style lang="postcss" scoped>

.shop-items {
    height: calc(100vh - 168px); /* 168 = top nav 100 + content pad Y 68 */
    min-height: 440px; /* Card height + wrapper padding */ 
}

</style>
